.todo-input {
    border-radius: 5px;
    border: none;
    width: 270px;
    height: 50px;
    margin-right: 10px;
    margin-bottom: 15px;
}

.add-btn {
    background-color:#fc6e6e;
    border: none;
    border-radius: 5px;
    color: white;
    height: 50px;
    width: 100px;
    font-size: larger;
    cursor: pointer;
}

input {
    padding-left: 10px;
    font-size: larger;
}

textarea:focus, input:focus{
    outline: none;
}